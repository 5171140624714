<template>
  <div class="password-recovery">
    <div class="panel">
      <form-password-recovery @login="goToLoginPage"></form-password-recovery>
    </div>
  </div>
</template>

<script>
  import FormPasswordRecovery from '@/components/Forms/FormPasswordRecovery.vue'

  export default {
    components: {
      FormPasswordRecovery
    },

    methods: {
      goToLoginPage() {
        this.$router.push({ name: 'login' })
      },

      ...mapActions('system', [
        'openAuthModal'
      ])
    }
  }
</script>

<style lang="scss">
  .password-recovery {
    max-width: 600px;
    margin: 0 auto;
    padding-top: 20px;

    .panel {
      padding: 20px;
      margin: 0 20px;

      background: #FFFFFF;
      box-shadow: 0 6px 12px 0 rgba(54,76,106,0.20);
      border-radius: 8px;
    }
  }

  @media screen and (min-width: 500px) {
    .password-recovery {
      .panel {
        padding: 40px;
      }
    }
  }
</style>