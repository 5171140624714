<template>
  <div class="password-recovery" :class="$mq">
    <template v-if="!showSuccessMessage">
      <div class="title">
        {{ $t('forms.formPasswordRecovery.title') }}
      </div>
      <div class="description">
        {{ $t('forms.formPasswordRecovery.description') }}
      </div>

      <base-text-box ref="email"
                     name="email"
                     v-model="formData.credentials.email"
                     rules="required|email"
                     :show-validation-error="true"
                     :rounded="true"
                     :placeholder="$t('forms.formPasswordRecovery.emailPlaceholder')"></base-text-box>

      <base-button class="full-width"
                   :loading="loading"
                   @click="submit">
        {{ $t('forms.formPasswordRecovery.recoverButton') }}
      </base-button>

      <div class="to-login">
        <a href="#" @click.prevent="$emit('login')" class="to-login-link">
          {{ $t('forms.formPasswordRecovery.toLogin') }}
        </a>
      </div>
    </template>

    <template v-else>
      <div class="title">
        {{ $t('forms.formPasswordRecovery.successTitle') }}
      </div>
      <div class="description">
        {{ $t('forms.formPasswordRecovery.successDescription') }}
      </div>

      <base-button class="button-login"
                   @click="$emit('login')">
        {{ $t('forms.formPasswordRecovery.toLogin') }}
      </base-button>
    </template>
  </div>
</template>

<script>
  import { requestRestore } from '@/services/auth'
  import hasBackgroundMixin from '@/services/mixins/hasBackgroundMixin.js'

  export default {
    data() {
      return {
        formData: {
          engine: 'email',
          credentials: {
            'email': ''
          }
        },
        loading: false,
        showSuccessMessage: false
      }
    },

    mixins: [hasBackgroundMixin],

    methods: {
      submit() {
        this.loading = true

        this.$refs.email.validate().then(result => {
          if (!result) {
            this.loading = false
            return
          }

          requestRestore(this.formData).then(response => {
            this.loading = false
            this.showSuccessMessage = true
          })
        })
      },

      openLoginModal() {
        this.$emit('close')
        this.openAuthModal({ content: 'login' })
      },

      openRegisterAgentModal() {
        this.$emit('close')
        this.openAuthModal({ content: 'registerAgent' })
      },

      openRegisterClientModal() {
        this.$emit('close')
        this.openAuthModal({ content: 'registerClient' })
      },

      ...mapActions('system', [
        'openAuthModal'
      ])
    }
  }
</script>

<style lang="scss" scoped>


  .password-recovery {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 600px;

    &.mobile {
      max-width: 400px;
    }

    .title {
      font-size: 26px;
      font-weight: bold;
      color: $primary-color;
      text-align: center;
      margin-bottom: 20px;
    }

    .description {
      font-size: 14px;
      color: #797979;
      margin-bottom: 30px;
    }

    .to-login {
      text-align: center;
      margin-top: 20px;

      .to-login-link {
        text-transform: uppercase;
      }
    }

    .button-login {
      width: 100%;
    }
  }

  .footer {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    text-align: center;
    transform: translateY(100%);

    .title {
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.3em;
      margin-bottom: 12px;
    }

    .link {
      display: block;
      margin-bottom: 4px;

      font-size: 14px;
      color: #fff;
    }
  }
</style>
